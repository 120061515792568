import { SendEmailParams } from 'types/mail/mailTypes'
import { validateEmail } from 'validations/mail/mailValidate'
import { sendEmail as sendEmailAPI } from 'api/mail/sendEmail'

export const sendEmail = async ({
  name,
  company,
  email,
  message,
}: SendEmailParams) => {
  const validatedEmail = validateEmail({ name, company, email, message })
  if (validatedEmail.error) return validatedEmail
  return await sendEmailAPI({ name, company, email, message })
}
