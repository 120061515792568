import React from 'react'
import { BrowserRouter as RouterReact, Routes, Route } from 'react-router-dom'
import Index from 'components/index'

export default function Router() {
  return (
    <RouterReact>
      <Routes>
        <Route index element={<Index />} />
      </Routes>
    </RouterReact>
  )
}
