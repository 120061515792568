import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import titleImg from 'img/main/title.svg'
import titleImgSmd from 'img/main/title-smd.svg'
import { ReactComponent as Occam } from 'img/main/occam.svg'
import { ReactComponent as DefiYield } from 'img/main/defiyield.svg'
import { ReactComponent as Humanode } from 'img/main/hmn.svg'
import { ReactComponent as Symbiosis } from 'img/main/symbiosis.svg'
import { ReactComponent as Transfero } from 'img/main/transfero.svg'
import useWindowSize from 'helpers/utils/useWindowSize'

function Main() {
  const { width, height } = useWindowSize()

  return (
    <Root>
      <Container>
        <Row>
          <LeftSide>
            <Title />
            <SubTitle>
              DAOs upholding the foundation of the interchain communication
              stack enabling safe messaging, liquidity connectivity and
              community mobility
            </SubTitle>
            {width > 930 && height > 450 && (
              <Founded>
                <FoundedText>Founded by:</FoundedText>
                <ItemsRow>
                  <FoundedItem
                    href={'https://symbiosis.finance/'}
                    target={'_blank'}
                  >
                    <Symbiosis />
                  </FoundedItem>
                  <FoundedItem href={'https://occam.fi/'} target={'_blank'}>
                    <Occam />
                  </FoundedItem>
                  <FoundedItem
                    href={'https://transfero.com/?lang=en'}
                    target={'_blank'}
                  >
                    <Transfero />
                  </FoundedItem>
                </ItemsRow>
                <ItemsRow>
                  <FoundedItem
                    href={'https://defiyield.app/'}
                    target={'_blank'}
                  >
                    <DefiYield />
                  </FoundedItem>
                  <FoundedItem href={'https://humanode.io/'} target={'_blank'}>
                    <Humanode />
                  </FoundedItem>
                </ItemsRow>
              </Founded>
            )}
          </LeftSide>
          <About>
            <HorizontalBorder />
            <Text>
              The <span>Interchain Alliance</span> has been formed by a group of
              pioneering blockchain devshops, research teams, DAOs,
            </Text>
            <SmallText>
              and individuals as a lens to focus our knowledge and experience
              towards building infrastructure enabling seamless interchain
              movement of capital, liquidity and communities towards maximum
              capital efficiency.
            </SmallText>
          </About>
          {(width <= 930 || height < 450) && (
            <Founded>
              <FoundedText>Founded by:</FoundedText>
              <ItemsRow>
                <FoundedItem
                  href={'https://symbiosis.finance/'}
                  target={'_blank'}
                >
                  <Symbiosis />
                </FoundedItem>
                <FoundedItem href={'https://occam.fi/'} target={'_blank'}>
                  <Occam />
                </FoundedItem>
                <FoundedItem
                  href={'https://transfero.com/?lang=en'}
                  target={'_blank'}
                >
                  <Transfero />
                </FoundedItem>
                <FoundedItem href={'https://defiyield.app/'} target={'_blank'}>
                  <DefiYield />
                </FoundedItem>
                <FoundedItem href={'https://humanode.io/'} target={'_blank'}>
                  <Humanode />
                </FoundedItem>
              </ItemsRow>
            </Founded>
          )}
        </Row>
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0%;
  // ${({ theme }) => theme.adaptive.xl} {
  //   padding-top: 77px;
  // }
  // ${({ theme }) => theme.adaptive.lg} {
  //   padding-top: 66px;
  // }
  // ${({ theme }) => theme.adaptive.slg} {
  //   padding-top: 57px;
  // }
  // ${({ theme }) => theme.adaptive.md} {
  //   padding-top: 42px;
  // }
  ${({ theme }) => theme.adaptive.smd} {
    padding-top: 49px;
    height: auto;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 37px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.smd} {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const About = styled.div`
  position: relative;
  width: 688px;
  height: 667px;
  padding: 100px 66px 120px 70px;
  background: #26232b;
  border: 1px solid #a6a6a6;
  border-radius: 0px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 602px;
    height: 584px;
    padding: 88px 58px 105px 61px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 570px;
    height: 501px;
    padding: 75px 50px 90px 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 490px;
    height: 431px;
    padding: 65px 43px 77px 45px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 454px;
    height: 321px;
    padding: 48px 32px 58px 34px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 100%;
    height: 627px;
    padding: 80px 30px 120px;
    order: 3;
    border: 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    height: 916px;
    padding: 80px 30px 120px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 714px;
    padding: 62px 23px 94px;
  }
`

const Text = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 34px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 28px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 432px;
    font-size: 24px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 364px;
    font-size: 21px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 294px;
    font-size: 18px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: auto;
    font-size: 32px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 352px;
    margin: 0 auto;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 24px;
    width: 275px;
    margin-bottom: 27px;
  }
  span {
    color: #5ad2a7;
  }
`

const SmallText = styled.div`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  color: #a6a6a6;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 352px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 275px;
    font-size: 18px;
  }
`

const LeftSide = styled.div`
  position: relative;
`

const Title = styled.div`
  position: relative;
  width: 942px;
  height: 240px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 824px;
    height: 207px;
    margin-bottom: 22px;
    margin-left: 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 707px;
    height: 167px;
    margin-bottom: 21px;
    margin-left: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 608px;
    height: 143px;
    margin-bottom: 18px;
    margin-left: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 374px;
    height: 88px;
    margin-bottom: 14px;
    margin-left: -5px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    order: 1;
    width: 600px;
    height: 225px;
    background: url('${titleImgSmd}') center no-repeat;
    background-size: cover;
    margin-bottom: 0;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 410px;
    height: 186px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 320px;
    height: 147px;
  }
`

const SubTitle = styled.div`
  font-family: 'Alma Mono';
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  color: #727272;
  width: 800px;
  margin-left: 105px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    width: 700px;
    margin-left: 99px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    width: 550px;
    margin-left: 93px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    width: 500px;
    margin-left: 70px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    width: 304px;
    margin-left: 31px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    order: 2;
    width: 540px;
    margin: 30px auto 44px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 350px;
    margin: 30px auto 52px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 275px;
    margin: 23px auto 26px;
    font-size: 18px;
  }
`

const HorizontalBorder = styled.div`
  position: absolute;
  left: -70vw;
  top: 397px;
  height: 1px;
  width: 70vw;
  background: #a6a6a6;
  ${({ theme }) => theme.adaptive.xl} {
    top: 361px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 295px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 248px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 188px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    display: none;
  }
`

const Founded = styled.div`
  margin-top: 125px;
  margin-left: 105px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 110px;
    margin-left: 99px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 90px;
    margin-left: 93px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 70px;
    margin-left: 70px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 55px;
    margin-left: 31px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    order: 4;
    margin-top: 80px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 62px;
  }
`

const FoundedText = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  color: #5ad2a7;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    margin-bottom: 28px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
    margin-bottom: 36px;
  }
`

const FoundedItem = styled.a`
  display: block;
  cursor: pointer;
  margin-right: 75px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-right: 65px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-right: 60px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-right: 50px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-right: 75px;
    margin-bottom: 48px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-right: 0;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 30px;
  }
  &:last-child {
    margin: 0;
  }
  svg {
    display: block;
    max-width: 220px;
    height: auto;
    ${({ theme }) => theme.adaptive.xl} {
      max-width: 180px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      max-width: 150px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      max-width: 125px;
    }
    ${({ theme }) => theme.adaptive.md} {
      max-width: 80px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      max-width: 200px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin: 0 auto;
    }
    ${({ theme }) => theme.adaptive.xs} {
      max-width: 165px;
    }
  }
`

const ItemsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 28px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 24px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-top: 41px;
    flex-wrap: wrap;
    justify-content: center;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin: 0;
  }
  &:last-child {
    justify-content: center;
  }
`
