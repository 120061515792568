const aboutList = [
  {
    title: 'INSTANT LIQUIDITY MOBILITY.',
    text: 'UX is key for adoption. Fast finality and user friendly settlement times.',
  },
  {
    title: 'MESSAGING INTEROPERABILITY.',
    text: 'Cross-chain contract calls are as important as liquidity mobility. Interchain apps, subject to settlement time constraints, are the future of DApps.',
  },
  {
    title: 'SOVEREIGNYT IN TRANSACTIONS.',
    text: 'No centralized services to be a part of the alliance.',
  },
  {
    title: 'LIQUIDITY CONCENTRATION AND MINIMIZATION OF INEFFICIENCIES',
    text: 'that arise as a result of attempts to create the world of interchain.',
  },
  {
    title: 'ZERO-KNOWLEDGE CRYPTOGRAPHY AND MPC',
    text: 'is the only tech stack that simultaneously enable scalability, decentralization and security in inter blockchain communication and interoperability;',
  },
  {
    title: 'CONSENSUAL INTERCHAIN COMPOSABILITY OF THE WEB3 FOOTPRINT.',
    text: 'Users should be able to carry cross-chain their web3 identity/history to enable the evaluation of one’s interchain merit. Of course, subject to their consent.',
  },
  {
    title: 'INTERCHAIN DAOs.',
    text: 'Governance to be exercised on the level of a community, not bound by any given technological platform. DAO treasuries to be diversified cross-chain for maximum capital efficiency.',
  },
  {
    title: 'CYBERSEC EXCELLENCE.',
    text: 'Interchain is an uncharted territory. We call for the creation of unified cyber security standards for IBC and peer review of every commit. Only open source codebase.',
  },
]

export default aboutList
