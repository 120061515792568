import React from 'react'
import styled from 'styled-components'
import Header from 'components/Header'
import Main from 'components/Main'
import Panel from 'components/Panel'
import PrinciplesPanel from 'components/PrinciplesPanel'
import { useStore } from 'effector-react'
import {
  $isFormPanel,
  $isPrinciplesPanel,
  setisFormPanel,
  setisPrinciplesPanel,
} from 'store/panels'
import FormPanel from 'components/FormPanel'

function Index() {
  const isPrinciplesPanel = useStore($isPrinciplesPanel)
  const isFormPanel = useStore($isFormPanel)

  return (
    <Root>
      <Header />
      <Main />
      <Panel />
      <PanelsWrapper
        onClick={() => {
          setisPrinciplesPanel(false)
          setisFormPanel(false)
        }}
        isOpen={isPrinciplesPanel || isFormPanel}
      />
      <PrinciplesPanel />
      <FormPanel />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.adaptive.smd} {
    height: auto;
  }
`

const PanelsWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(38, 35, 43, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: 0.2s;
  z-index: ${({ isOpen }) => (isOpen ? '1' : '-1')};
`
