import { Base64 } from 'js-base64'
import { API_BASIC_AUTH_LOGIN, API_BASIC_AUTH_PASS } from 'configs/app'
import { SendEmailParams } from 'types/mail/mailTypes'

export const getMailPostOptions = (data: SendEmailParams) => {
  let auth = ''
  if (API_BASIC_AUTH_LOGIN && API_BASIC_AUTH_PASS) {
    const authString = `${API_BASIC_AUTH_LOGIN}:${API_BASIC_AUTH_PASS}`
    auth = `Basic ${Base64.encode(authString)}`
  }

  return {
    method: 'POST',
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
}
