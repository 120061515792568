import { SendEmailParams } from 'types/mail/mailTypes'
import { getMailPostOptions } from './options'
import { API_URL } from 'configs/app'

export const sendEmail = async ({
  name,
  company,
  email,
  message,
}: SendEmailParams) => {
  const response = await fetch(
    `${API_URL}/mails`,
    getMailPostOptions({ name, company, email, message })
  )
  return response.json()
}
