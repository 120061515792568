import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import logoImg from 'img/header/logo.svg'
import principlesImg from 'img/header/principles-icon.svg'
import { setisPrinciplesPanel } from '../../store/panels'
import { DECK_LINK } from 'configs/app'

function Header() {
  return (
    <Root>
      <Container>
        <VerticalBorder />
        <VerticalBorder />
        <Row>
          <Border />
          <Border />
          <Logo />
          <Principles
            onClick={() => {
              setisPrinciplesPanel(true)
            }}
          >
            <PrinciplesIcon />
            <PrinciplesText>
              CORE PRINCIPLES OF THE INTERCHAIN ALLIANCE
            </PrinciplesText>
          </Principles>
          <DeckButton href={DECK_LINK} target={'_blank'}>
            DECK
          </DeckButton>
        </Row>
      </Container>
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  ${Container} {
    padding-top: 88px;
    ${({ theme }) => theme.adaptive.xl} {
      padding-top: 77px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      padding-top: 66px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      padding-top: 57px;
    }
    ${({ theme }) => theme.adaptive.md} {
      padding-top: 42px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      width: 600px;
      padding-top: 0;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 100%;
    }
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 88px;
  ${({ theme }) => theme.adaptive.xl} {
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    height: auto;
    flex-wrap: wrap;
  }
`

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 1px;
  background: #a6a6a6;
  ${({ theme }) => theme.adaptive.smd} {
    display: none;
  }
  &:nth-child(2) {
    top: unset;
    bottom: 0;
  }
`

const Logo = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 145px;
  height: 145px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 128px;
    height: 128px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 110px;
    height: 110px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 96px;
    height: 96px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 70px;
    height: 70px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
    width: 150px;
    height: 150px;
    margin: 61px auto 0;
    order: 3;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 118px;
    height: 118px;
    margin: 48px auto 0;
  }
`

const DeckButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 88px;
  background: #5ad2a7;
  border: 1px solid #a6a6a6;
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.07em;
  color: #ffffff;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 152px;
    height: 77px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 131px;
    height: 66px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 112px;
    height: 57px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 92px;
    height: 42px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 100%;
    height: 80px;
    font-size: 22px;
    order: 2;
    border: 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 62px;
    font-size: 16px;
  }
  &:hover {
    background: #7aefc5;
  }
`

const Principles = styled.div`
  display: flex;
  align-items: center;
  width: 515px;
  height: 88px;
  border: 1px solid #a6a6a6;
  padding: 0 30px;
  border-left: 0;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 450px;
    height: 77px;
    padding: 0 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 440px;
    height: 66px;
    padding: 0 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 0 19px;
    width: 379px;
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 0 14px;
    width: 362px;
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    padding: 0 30px;
    width: 100%;
    height: 80px;
    border: 0;
    justify-content: center;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 0 20px;
    height: 62px;
  }
  &:hover {
    background: rgba(38, 35, 43, 0.1);
  }
`

const PrinciplesIcon = styled.div`
  width: 15px;
  height: 15px;
  background: url('${principlesImg}') center no-repeat;
  background-size: cover;
  margin-right: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 13px;
    height: 13px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 11px;
    height: 11px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 9px;
    height: 9px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 7px;
    height: 7px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
`

const PrinciplesText = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.07em;
  color: #26232b;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 512px;
    text-align: center;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 322px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 206px;
    text-align: left;
  }
`

const VerticalBorder = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 1px;
  background: #a6a6a6;
  ${({ theme }) => theme.adaptive.smd} {
    display: none;
  }
  &:nth-child(2) {
    right: 687px;
    ${({ theme }) => theme.adaptive.xl} {
      right: 601px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      right: 569px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      right: 489px;
    }
    ${({ theme }) => theme.adaptive.md} {
      right: 453px;
    }
  }
`
