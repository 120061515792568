import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import okIcon from 'img/form/ok-icon.svg'
import bgImg from 'img/form/bg.svg'

interface Props {
  isOpen: boolean
}

function Success(props: Props) {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  useEffect(() => {
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <Root isSuccess={isOpen}>
      <Cont>
        <SuccessTitle>
          Thank you<span>for reaching out</span>
        </SuccessTitle>
        <Icon />
        <Text>We will revert shortly.</Text>
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
        >
          OK !
        </Button>
      </Cont>
    </Root>
  )
}

export default Success

const Root = styled.div<{ isSuccess: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ isSuccess }) => (isSuccess ? '3' : '-1')};
  opacity: ${({ isSuccess }) => (isSuccess ? '1' : '0')};
  width: 100%;
  height: 100%;
  background: #26232b;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.smd} {
    position: fixed;
    left: unset;
    right: 0;
  }
`

const Cont = styled.div`
  width: 732px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 643px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 550px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 474px;
    padding-left: 39px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 351px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 530px;
    margin: 0 auto;
    padding-left: 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 340px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 250px;
  }
`

const SuccessTitle = styled.div`
  position: relative;
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 91px;
  margin-top: 217px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 79px;
    margin-top: 188px;
    font-size: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 37px;
    margin-bottom: 68px;
    margin-top: 161px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 32px;
    margin-bottom: 59px;
    margin-top: 139px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 24px;
    margin-bottom: 43px;
    margin-top: 102px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 50px;
    margin-bottom: 91px;
    margin-top: 149px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 32px;
    margin-bottom: 115px;
    margin-top: 100px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 25px;
    margin-bottom: 89px;
    margin-top: 78px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -219px;
    left: 50%;
    transform: translateX(-50%);
    width: 1014px;
    height: 1014px;
    background: url('${bgImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: -192px;
      width: 887px;
      height: 887px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -165px;
      width: 760px;
      height: 760px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -142px;
      width: 655px;
      height: 655px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -104px;
      width: 480px;
      height: 480px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      top: -154px;
      width: 1014px;
      height: 1014px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: -168px;
      width: 952px;
      height: 952px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: -131px;
      width: 743px;
      height: 743px;
    }
  }
  span {
    display: block;
  }
`

const Icon = styled.div`
  width: 163px;
  height: 163px;
  margin: 0 auto;
  margin-bottom: 92px;
  background: url('${okIcon}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 116px;
    height: 116px;
    margin-bottom: 80px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 100px;
    height: 100px;
    margin-bottom: 69px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 86px;
    height: 86px;
    margin-bottom: 59px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 43px;
    height: 63px;
    margin-bottom: 63px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 133px;
    height: 133px;
    margin-bottom: 92px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 116px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 104px;
    height: 104px;
    margin-bottom: 90px;
  }
`

const Text = styled.div`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #a6a6a6;
  margin-bottom: 255px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
    margin-bottom: 223px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    margin-bottom: 193px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-bottom: 165px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 121px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    margin-bottom: 256px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 70px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
    margin-bottom: 56px;
  }
`

const Button = styled.button`
  position: relative;
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  height: 69px;
  background: #5ad2a7;
  transition: 0.4s;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    height: 61px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    height: 69px;
  }
  &:hover {
    background: #7aefc5;
  }
`
