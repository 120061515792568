import React from 'react'
import styled, { css } from 'styled-components'
import principlesList from 'store/principlesList'
import closeImg from 'img/principlesPanel/close-icon.svg'
import { $isPrinciplesPanel, setisPrinciplesPanel } from 'store/panels'
import { useStore } from 'effector-react'
import { Scrollbars } from 'react-custom-scrollbars-2'

function PrinciplesPanel() {
  const isOpen = useStore($isPrinciplesPanel)

  // @ts-ignore
  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {}
    return (
      <div className={'track'} style={{ ...style, ...trackStyle }} {...props} />
    )
  }

  // @ts-ignore
  const renderThumb = ({ style, ...props }) => {
    const trackStyle = {
      width: '6px',
      left: '-2px',
      height: '10%',
      borderRadius: 6,
      backgroundColor: 'rgba(166, 166, 166, 0.2)',
    }
    return (
      <div className={'thumb'} style={{ ...style, ...trackStyle }} {...props} />
    )
  }

  return (
    <Root isOpen={isOpen}>
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        renderTrackHorizontal={renderTrack}
        renderTrackVertical={renderTrack}
        thumbSize={110}
      >
        <Cont>
          <Title>
            Core Principles of the
            <span>Interchain Alliance</span>
          </Title>
          <Desc>
            We are here to build a freer world, where communities are not bound
            by an ideology or technology of any particular network and can
            efficiently and safely redeploy capital and liquidity carrying their
            digital wealth and Web3 footprint interchain.
          </Desc>
          <Green>
            The Peripheral Principles that underpin the achievement of our
            mission are:
          </Green>
          <List>
            {principlesList.map((item, idx) => {
              return (
                <Item key={idx}>
                  <ItemTitle>
                    <span>{`/0${idx + 1} `}</span>
                    {item.title}
                  </ItemTitle>
                  <ItemText>{item.text}</ItemText>
                </Item>
              )
            })}
          </List>
        </Cont>
      </Scrollbars>
      <Close
        onClick={() => {
          setisPrinciplesPanel(false)
        }}
      />
    </Root>
  )
}

export default PrinciplesPanel

const Root = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 824px;
  height: 100vh;
  background: #26232b;
  z-index: 2;
  overflow: auto;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 721px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 618px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 532px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 395px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 100%;
  }
  .track {
    width: 2px !important;
    top: 10%;
    right: 4.8%;
    height: 80%;
    border-radius: 0;
    background: rgba(114, 114, 114, 0.15);
  }
`

const Title = styled.div`
  margin-top: 90px;
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  margin-bottom: 60px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 79px;
    font-size: 28px;
    margin-bottom: 53px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 68px;
    font-size: 24px;
    margin-bottom: 45px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 58px;
    font-size: 20px;
    margin-bottom: 45px;
    padding-left: 39px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 43px;
    font-size: 14px;
    margin-bottom: 24px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-top: 80px;
    font-size: 32px;
    margin-bottom: 60px;
    padding-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-left: 20px;
  }
  span {
    display: block;
    color: #5ad2a7;
  }
`

const Cont = styled.div`
  width: 668px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 585px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 501px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 432px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 320px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 530px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 340px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 270px;
  }
`

const TextCss = css`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: #a6a6a6;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 16px;
  }
`

const Desc = styled.div`
  ${TextCss};
  margin-bottom: 20px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 18px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 15px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 13px;
    padding-left: 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 10px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-bottom: 20px;
    padding-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-left: 20px;
  }
`

const Green = styled.div`
  font-family: 'Alma Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: #5ad2a7;
  margin-bottom: 60px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 53px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 45px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 39px;
    padding-left: 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 24px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-bottom: 60px;
    padding-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-left: 20px;
  }
`

const List = styled.div``

const Item = styled.div`
  position: relative;
  margin-bottom: 60px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 53px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 45px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 39px;
    padding-left: 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 24px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-bottom: 60px;
    padding-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-left: 20px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -32px;
    width: 100%;
    height: 1px;
    background: linear-gradient(
      90deg,
      #ffffff 16.88%,
      #ffffff 16.88%,
      #333333 37.47%
    );
    ${({ theme }) => theme.adaptive.xl} {
      bottom: -28px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      bottom: -25px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      bottom: -20px;
    }
    ${({ theme }) => theme.adaptive.md} {
      bottom: -13px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      bottom: -29px;
    }
  }
  &:last-child {
    &:before {
      content: unset;
    }
  }
`

const ItemTitle = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: 30px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    margin-bottom: 30px;
  }
  span {
    color: #5ad2a7;
  }
`

const ItemText = styled.div`
  ${TextCss};
`

const Close = styled.div`
  position: absolute;
  top: 35px;
  right: 28px;
  width: 18px;
  height: 18px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  opacity: 0.2;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    top: 30px;
    right: 24px;
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 26px;
    right: 21px;
    width: 13px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 19px;
    right: 15px;
    width: 10px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    right: 20px;
  }
  &:hover {
    opacity: 1;
  }
`
