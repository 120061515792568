import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Contact } from 'img/socials/contact.svg'
import { MEDIUM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'configs/app'
import { setisFormPanel } from '../../store/panels'

function Panel() {
  return (
    <Root>
      <Container>
        <Socials>
          {/*<SocialItem>*/}
          {/*  <Discord />*/}
          {/*</SocialItem>*/}
          <SocialItem href={MEDIUM_LINK} target={'_blank'}>
            <Medium />
          </SocialItem>
          <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
            <Telegram />
          </SocialItem>
          <SocialItem href={TWITTER_LINK} target={'_blank'}>
            <Twitter />
          </SocialItem>
          <SocialItem
            href={'mailto:contact@occam.fi'}
            target={'_blank'}
            // onClick={() => {
            //   setisFormPanel(true)
            // }}
          >
            <Contact />
          </SocialItem>
        </Socials>
        <CopyRight>© 2021-2022 Interchain Alliance.</CopyRight>
      </Container>
    </Root>
  )
}

export default Panel

const Root = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 88px;
  padding-bottom: 46px;
  width: 90px;
  height: 100vh;
  border-left: 1px solid #a6a6a6;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 77px;
    padding-bottom: 40px;
    width: 79px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 66px;
    padding-bottom: 44px;
    width: 68px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 57px;
    padding-bottom: 39px;
    width: 58px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 42px;
    padding-bottom: 15px;
    width: 43px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    padding-top: 66px;
    padding-bottom: 0;
    width: 100%;
    position: relative;
    right: unset;
    top: unset;
    height: auto;
    border: 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 51px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${({ theme }) => theme.adaptive.smd} {
    justify-content: flex-start;
    height: auto;
  }
`

const Socials = styled.div`
  width: 100%;
  ${({ theme }) => theme.adaptive.smd} {
    display: flex;
    justify-content: center;
    order: 2;
  }
`

const SocialItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 88px;
  border-top: 1px solid #a6a6a6;
  background: rgba(38, 35, 43, 0.05);
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 20%;
    height: 115px;
    border-right: 1px solid #a6a6a6;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 78px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 61px;
  }
  &:first-child,
  &:nth-child(2) {
    border: 0;
    ${({ theme }) => theme.adaptive.smd} {
      border-top: 1px solid #a6a6a6;
      border-right: 1px solid #a6a6a6;
    }
  }
  &:first-child {
    ${({ theme }) => theme.adaptive.smd} {
      border-left: 1px solid #a6a6a6;
    }
  }
  &:last-child {
    border-bottom: 1px solid #a6a6a6;
    background: #5ad2a7;
    svg {
      fill: #ffffff;
    }
    ${({ theme }) => theme.adaptive.smd} {
      border-bottom: 0;
      border-right: 0;
      border-right: 1px solid #a6a6a6;
    }
    &:hover {
      background: #7aefc5;
      svg {
        fill: #ffffff;
      }
    }
  }
  svg {
    display: block;
    fill: #26232b;
    max-width: 20px;
    max-height: 16px;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      max-width: 18px;
      max-height: 14px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      max-width: 15px;
      max-height: 12px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      max-width: 13px;
      max-height: 10px;
    }
    ${({ theme }) => theme.adaptive.md} {
      max-width: 10px;
      max-height: 8px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      max-width: 27px;
      max-height: 21px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      max-width: 18px;
      max-height: 14px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      max-width: 14px;
      max-height: 11px;
    }
  }
  &:hover {
    background: rgba(38, 35, 43, 0.15);
    svg {
      fill: #4b4653;
    }
  }
`

const CopyRight = styled.div`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: #727272;
  transform: rotate(-90deg);
  width: 324px;
  margin-bottom: 162px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 284px;
    margin-bottom: 142px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 243px;
    margin-bottom: 121px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    order: 1;
    width: 100%;
    margin-bottom: 60px;
    font-size: 16px;
    transform: unset;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 40px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 31px;
    font-size: 12px;
  }
`
