import styled from 'styled-components'

const theme = {
  adaptive: {
    xl: '@media screen and (max-width: 1920px), screen and (max-height: 930px)',
    lg: '@media screen and (max-width: 1710px), screen and (max-height: 810px)',
    slg: '@media screen and (max-width: 1495px), screen and (max-height: 700px)',
    md: '@media screen and (max-width: 1313px), screen and (max-height: 600px)',
    smd: '@media screen and (max-width: 930px), screen and (max-height: 450px)',
    sm: '@media screen and (max-width: 600px)',
    xs: '@media screen and (max-width: 410px)',
  },
}

export const Container = styled.div`
  position: relative;
  width: 1735px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.xl} {
    width: 1532px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 1312px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 1130px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 842px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 600px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
  }
`

export default theme
