import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import closeImg from 'img/principlesPanel/close-icon.svg'
import { $isFormPanel, setisFormPanel } from 'store/panels'
import { useStore } from 'effector-react'
import { SendEmailErrors } from 'types/mail/mailTypes'
import { sendEmail } from 'helpers/sendEmail'
import { clone, has } from 'lodash'
import Success from './Success'

const DEFAULT_ERRORS_STATE: SendEmailErrors = {
  name: false,
  company: false,
  email: false,
  message: false,
}

function FormPanel() {
  const isOpen = useStore($isFormPanel)
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [fieldErrors, setFieldErrors] = useState(DEFAULT_ERRORS_STATE)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const resetForm = () => {
    setMessage('')
    setName('')
    setCompany('')
    setEmail('')
    setFieldErrors(DEFAULT_ERRORS_STATE)
    setErrorMessage('')
  }

  const handleSubmit = async () => {
    setIsSending(true)
    const response = await sendEmail({ name, company, email, message })
    setIsSending(false)
    setIsSuccess(true)
    if (response.error) {
      setErrorMessage(response.error.details[0].message)
      const defaultState = clone(DEFAULT_ERRORS_STATE)
      const errorField: keyof SendEmailErrors =
        response.error.details[0].context.key
      if (has(fieldErrors, errorField)) {
        defaultState[errorField] = true
        setFieldErrors({ ...defaultState })
      }
      return
    }
    setIsSuccess(true)
    setTimeout(() => {
      setIsSuccess(false)
    }, 5000)
    resetForm()
  }

  return (
    <Root isOpen={isOpen} isSuccess={isSuccess}>
      <Cont>
        <Title>JOIN US</Title>
        <Green>“THE INTERCHAIN ALLIANCE IS GROWING.”</Green>
        <Desc>Join us in building an interchain future.</Desc>
        <Form>
          <Field isError={fieldErrors.name}>
            <Label>
              Name<span>*</span>
            </Label>
            <Input
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            {fieldErrors.name && <Error>{errorMessage}</Error>}
          </Field>
          <Field isError={fieldErrors.company}>
            <Label>
              Company
              <span>*</span>
            </Label>
            <Input
              type="text"
              value={company}
              onChange={(event) => setCompany(event.target.value)}
            />
            {fieldErrors.company && <Error>{errorMessage}</Error>}
          </Field>
          <Field isError={fieldErrors.email}>
            <Label>
              Email
              <span>*</span>
            </Label>
            <Input
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            {fieldErrors.email && <Error>{errorMessage}</Error>}
          </Field>
          <Field isError={fieldErrors.message}>
            <Label>
              Message
              <span>*</span>
            </Label>
            <TextArea
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            {fieldErrors.message && <Error>{errorMessage}</Error>}
          </Field>
        </Form>
        <Button onClick={handleSubmit}>Send</Button>
      </Cont>
      <Success isOpen={isSuccess} />
      <Close
        onClick={() => {
          setisFormPanel(false)
        }}
      />
    </Root>
  )
}

export default FormPanel

const Root = styled.div<{ isOpen: boolean; isSuccess: boolean }>`
  position: absolute;
  bottom: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  width: 824px;
  height: 100vh;
  background: #26232b;
  z-index: 2;
  overflow: auto;
  transition: 0.4s;
  padding-bottom: 70px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  ${({ theme }) => theme.adaptive.xl} {
    width: 721px;
    padding-bottom: 62px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 618px;
    padding-bottom: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 532px;
    padding-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 395px;
    padding-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    position: fixed;
    width: 100%;
    padding-bottom: 45px;
    height: 100%;
    overflow-x: hidden;
  }
`

const Cont = styled.div`
  width: 732px;
  padding-left: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 643px;
    padding-left: 44px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 550px;
    padding-left: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 474px;
    padding-left: 39px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 351px;
    padding-left: 24px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    width: 530px;
    margin: 0 auto;
    padding: 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 340px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 250px;
  }
`

const Title = styled.div`
  margin-top: 90px;
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 79px;
    font-size: 28px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 68px;
    font-size: 24px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 58px;
    font-size: 20px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 43px;
    font-size: 14px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-top: 80px;
    font-size: 32px;
    margin-bottom: 40px;
  }
  span {
    display: block;
    color: #5ad2a7;
  }
`

const TextCss = css`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: #a6a6a6;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 16px;
  }
`

const Green = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  color: #5ad2a7;
  margin-bottom: 20px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    margin-bottom: 20px;
  }
`

const Desc = styled.div`
  ${TextCss};
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-bottom: 40px;
  }
`

const Button = styled.button`
  font-family: 'Alma Mono';
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  height: 69px;
  background: #5ad2a7;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    height: 61px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    height: 69px;
  }
  &:hover {
    background: #7aefc5;
  }
`

const Form = styled.div``

const InputCss = css`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  color: #ffffff;
  padding: 21px 20px;
  border: 0;
  outline: none;
  background: rgba(114, 114, 114, 0.05);
  width: 100%;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    padding: 18px 18px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
    padding: 15px 15px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    padding: 14px 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 14px;
    padding: 8px 10px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 22px;
    padding: 21px 20px;
  }
`

const Input = styled.input`
  ${InputCss};
  height: 70px;
  ${({ theme }) => theme.adaptive.xl} {
    height: 62px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 45px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    height: 70px;
  }
`

const TextArea = styled.textarea`
  ${InputCss};
  height: 140px;
  resize: none;
  ${({ theme }) => theme.adaptive.xl} {
    height: 123px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 105px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 91px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 67px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    height: 140px;
  }
`

const Label = styled.div`
  font-family: 'Alma Mono';
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: #a6a6a6;
  margin-bottom: 13px;
  span {
    color: #5ad2a7;
  }
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 16px;
    margin-bottom: 13px;
  }
`

const Error = styled.div`
  font-family: 'Alma Mono';
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.07em;
  color: red;
  font-size: 16px;
  margin-top: 13px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    font-size: 16px;
    margin-top: 13px;
  }
`

const Field = styled.div<{ isError?: boolean }>`
  width: 100%;
  margin-bottom: 30px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    margin-bottom: 30px;
  }
  &:last-child {
    margin-bottom: 50px;
    ${({ theme }) => theme.adaptive.xl} {
      margin-bottom: 44px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-bottom: 38px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-bottom: 23px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-bottom: 16px;
    }
    ${({ theme }) => theme.adaptive.smd} {
      margin-bottom: 50px;
    }
  }
  ${Input}, ${TextArea} {
    ${({ isError }) => {
      if (isError)
        return css`
          border: 1px solid red;
        `
    }}
`

const Close = styled.div`
  position: absolute;
  top: 35px;
  right: 28px;
  width: 18px;
  height: 18px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  opacity: 0.2;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.lg} {
    top: 30px;
    right: 24px;
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 26px;
    right: 21px;
    width: 13px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 19px;
    right: 15px;
    width: 10px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.smd} {
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    right: 20px;
  }
  &:hover {
    opacity: 1;
  }
`
