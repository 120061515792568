import { createGlobalStyle } from 'styled-components'
import AlmaMonoRegularWoff from './fonts/AlmaMono-Regular.woff'
import AlmaMonoRegularWoff2 from './fonts/AlmaMono-Regular.woff2'
import AlmaMonoBoldWoff from './fonts/AlmaMono-Bold.woff'
import AlmaMonoBoldWoff2 from './fonts/AlmaMono-Bold.woff2'
import AlmaMonoHeavyWoff from './fonts/AlmaMono-Heavy.woff'
import AlmaMonoHeavyWoff2 from './fonts/AlmaMono-Heavy.woff2'
import AlmaMonoThinWoff from './fonts/AlmaMono-Thin.woff'
import AlmaMonoThinWoff2 from './fonts/AlmaMono-Thin.woff2'

const almoMono = {
  regular: {
    woff2: AlmaMonoRegularWoff2,
    woff: AlmaMonoRegularWoff,
  },
  bold: {
    woff2: AlmaMonoBoldWoff2,
    woff: AlmaMonoBoldWoff,
  },
  thin: {
    woff2: AlmaMonoThinWoff2,
    woff: AlmaMonoThinWoff,
  },
  heavy: {
    woff2: AlmaMonoHeavyWoff2,
    woff: AlmaMonoHeavyWoff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Alma Mono';
    src: local('Alma Mono'),
    url(${almoMono.regular.woff2}) format('woff2'),
    url(${almoMono.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  },
  @font-face {
    font-family: 'Alma Mono';
    src: local('Alma Mono'),
    url(${almoMono.bold.woff2}) format('woff2'),
    url(${almoMono.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Alma Mono';
    src: local('Alma Mono'),
    url(${almoMono.thin.woff2}) format('woff2'),
    url(${almoMono.thin.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Alma Mono';
    src: local('Alma Mono'),
    url(${almoMono.heavy.woff2}) format('woff2'),
    url(${almoMono.heavy.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
`
export default Fonts
